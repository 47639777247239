import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import RootState from '@vue-storefront/core/types/RootState'
import ConfigState from '../types/ConfigState'
import * as types from './mutation-types'
import { createLoadConfigsQuery } from '../helpers'
import { ConfigValueType } from '../types/ConfigValue'

const actions: ActionTree<ConfigState, RootState> = {
  async list (context, { path, start = 0, size = 300, entityType = 'config', sort = '', excludeFields = null, includeFields = null }) {
    const query = createLoadConfigsQuery({ path })
    const reviewResponse = await quickSearchByQuery({ query, start, size, entityType, sort, excludeFields, includeFields })

    if (reviewResponse.items) {
      context.commit(types.UPDATE_CONFIG_LIST, reviewResponse.items)
    }

    return reviewResponse
  },
  getValue ({ state }, path: string, valueType: ConfigValueType = ConfigValueType.STRING) {
    switch (valueType) {
      case ConfigValueType.STRING:
        return String(state.list[path])
      case ConfigValueType.ARRAY:
        return state.list[path].split(',')
      case ConfigValueType.JSON:
        return JSON.parse(state.list[path])
      default:
        return state.list[path]
    }
  }
}

export default actions
