import merge from 'lodash-es/merge'
import { orderStore } from '@vue-storefront/core/modules/order/store'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export const orderExtendedStore = merge(orderStore, {
  actions: {
    async handlePlacingOrderFailed ({ commit, dispatch, rootGetters }, { newOrder, currentOrderHash }) {
      // Reset cart to reset timer in backend
      const product = rootGetters['product/getCurrentProduct']
      await dispatch('cart/clear', { disconnect: true, sync: false }, { root: true })
      await dispatch('cart/addItem', { productToAdd: product }, { root: true })
      EventBus.$emit('notification-progress-stop')
    }
  }
})

export const orderExtended = orderExtendedStore
