import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import RootState from '@vue-storefront/core/types/RootState'
import TrustpilotState from './types/TrustpilotState'
import config from 'config'
import { Module } from 'vuex'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { SERVICE_INVITATION_URL } from './mutation-types';
import mutations from './mutations'

export const module: Module<TrustpilotState, RootState> = {
  namespaced: true,
  state: {
    invitationUrl: null
  },
  actions: {
    async getReviewInvitationLink ({ dispatch, commit }, { orderId }) {
      const res = await TaskQueue.execute({
        url: processLocalizedURLAddress(
          getApiEndpointUrl(config.trustpilot.endpoints, 'invitationLink')
        ),
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            orderId
          })
        }
      })

      const result = JSON.parse(res.result)
      if (res?.resultCode === 200 && result.url) {
        commit(SERVICE_INVITATION_URL, { invitationUrl: result.url })
      }

      return result || { status: 'failed' }
    }
  },
  mutations
}
