import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import UserState from '@vue-storefront/core/modules/user/types/UserState'
import RootState from '@vue-storefront/core/types/RootState'
import config from 'config'
import { Module } from 'vuex'

export const module: Module<UserState, RootState> = {
  namespaced: true,
  actions: {
    async getQuoteTime ({ dispatch, commit }, { quoteId }) {
      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(config.reservationTimer.getQuiteTime).replace('{{quoteId}}', quoteId),
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })

      if (response.code !== 200) {
        throw new Error(response.result.errorMessage)
      }

      return response
    }
  }
}
