import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { CatalogModule } from '@vue-storefront/core/modules/catalog'
import { CatalogNextModule } from '@vue-storefront/core/modules/catalog-next'
import { CartModule } from '@vue-storefront/core/modules/cart'
import { CheckoutModule } from '@vue-storefront/core/modules/checkout'
import { CompareModule } from '@vue-storefront/core/modules/compare'
import { WishlistModule } from '@vue-storefront/core/modules/wishlist'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { BreadcrumbsModule } from '@vue-storefront/core/modules/breadcrumbs'
import { UserModule } from '@vue-storefront/core/modules/user'
import { CmsModule } from '@vue-storefront/core/modules/cms'
import { GoogleTagManagerModule } from './google-tag-manager';
import { PaymentBackendMethodsModule } from './payment-backend-methods'
import { PaymentCashOnDeliveryModule } from './payment-cash-on-delivery'
import { NewsletterModule } from '@vue-storefront/core/modules/newsletter'
import { InitialResourcesModule } from '@vue-storefront/core/modules/initial-resources'
import { MagentoConfig } from './magento-config'
import { Evenemangsbiljetter } from './evenemangsbiljetter'
import { ReservationTimer } from './reservation-timer'
import { PaymentStripeModule } from './payment-stripe'
import { WiserNotifyModule } from './wiser-notify'
import { LogRocketModule } from './logrocket'
import { CartExtended } from './cart-extended';
import { registerModule } from '@vue-storefront/core/lib/modules'
import { OrderModuleExtended } from './order-extended'
import { ScrollHandler } from './scroll-handler'
import { Tickets } from './tickets';
import { Trustpilot } from './trustpilot';
import { Venues } from './venues';
import { PosthogModule } from './posthog'
import { GdprCookiePopup } from './gdpr-cookie-setup'
import { MicrosoftClarityModule } from './microsoft-clarity'
import { Popular } from './popular'
import { KlaviyoModule } from './vsf-klaviyo'
import { UrlRewrites } from './url-rewrites'

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules () {
  registerModule(UrlModule)
  registerModule(ScrollHandler)
  registerModule(CatalogModule)
  registerModule(CheckoutModule) // To Checkout
  registerModule(CartModule)
  registerModule(PaymentBackendMethodsModule)
  registerModule(PaymentCashOnDeliveryModule)
  registerModule(WishlistModule) // Trigger on wishlist icon click
  registerModule(NotificationModule)
  registerModule(UserModule) // Trigger on user icon click
  registerModule(CatalogNextModule)
  registerModule(CompareModule)
  registerModule(BreadcrumbsModule)
  registerModule(GoogleTagManagerModule)
  registerModule(CmsModule)
  registerModule(NewsletterModule)
  registerModule(InitialResourcesModule)
  registerModule(MagentoConfig)
  registerModule(Evenemangsbiljetter)
  registerModule(ReservationTimer)
  registerModule(PaymentStripeModule)
  registerModule(OrderModuleExtended)
  registerModule(WiserNotifyModule)
  registerModule(LogRocketModule)
  registerModule(CartExtended)
  registerModule(Tickets)
  registerModule(Trustpilot)
  registerModule(Venues)
  registerModule(PosthogModule)
  registerModule(GdprCookiePopup)
  registerModule(MicrosoftClarityModule)
  registerModule(Popular)
  registerModule(KlaviyoModule)
  registerModule(UrlRewrites)
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  // Example
]
