import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import ScrollState from '../types/ScrollState'

const mutations: MutationTree<ScrollState> = {
  [types.SET_SCROLL_RECORDS] (state, records) {
    state.scrollRecords = records
  },
  [types.UPDATE_SCROLL_RECORDS] (state, records) {
    state.scrollRecords = records
  },
  [types.SET_CURRENT_SCROLL_POSITION] (state, position) {
    state.scrollPosition = position
  }
}

export default mutations
