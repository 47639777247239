import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import RootState from 'core/types/RootState'
import { Posthog } from '.'

export const trackEvents = (posthog: Posthog, store: Store<RootState>) => {
  const storeView = currentStoreView()
  const currencyCode = storeView.i18n.currencyCode

  EventBus.$on('posthog-start-recording', () => {
    posthog.startSessionRecording()
  })

  EventBus.$on('buy_tickets_click', () => {
    posthog.capture('buy_tickets_click')
  })

  EventBus.$on('proceed_to_checkout', () => {
    posthog.capture('proceed_to_checkout_click')
  })

  EventBus.$on('address_input', () => {
    posthog.capture('address_input')
  })

  EventBus.$on('payment_details_input', () => {
    posthog.capture('payment_details_input')
  })

  EventBus.$on('order-after-placed', (event) => {
    posthog.capture('purchase', {
      order: event.order
    })
  })

  EventBus.$on('cart-before-add', (event) => {
    posthog.capture('add_to_cart', {
      currency: currencyCode,
      value: parseFloat(event.product.price_incl_tax.toFixed(2)),
      items: [
        {
          item_id: event.product.sku,
          item_name: event.product.name,
          price: event.product.price_incl_tax.toFixed(2),
          quantity: event.product.qty,
          item_category: event.product.category ? event.product.category[0].name : ''
        }
      ]
    });
  })

  EventBus.$on('leave_review_click', () => {
    posthog.capture('leave_review_click')
  })
}
