import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import UserState from '@vue-storefront/core/modules/user/types/UserState'
import RootState from '@vue-storefront/core/types/RootState'
import config from 'config'
import { Module } from 'vuex'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';

export const module: Module<UserState, RootState> = {
  namespaced: true,
  actions: {
    async getTickets ({ dispatch, commit }, { orderId }) {
      return TaskQueue.execute({
        url: processLocalizedURLAddress(
          getApiEndpointUrl(config.ticket, 'downloadTicket').replace('{{orderId}}', orderId + '')
        ),
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })
    }
  }
}
