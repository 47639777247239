import Vue from 'vue'

/**
 * Adds cache tags for the provided IDs prefixed by the given tag.
 * @param {string} tag - The prefix for cache tags.
 * @param {number[]} ids - The IDs to create tags for.
 */
const addCacheTag = (tag: string, ids: any[] = []): void => {
  const cacheTags = Vue.prototype.$cacheTags || { add: () => { } }

  if (ids.length === 0) {
    cacheTags.add(tag)
    return
  }

  ids.forEach(id => {
    cacheTags.add(`${tag}${id}`)
  })
}

export {
  addCacheTag
}
