import { module } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import scrollDataPersistPlugin from './store/scrollDataPersistPlugin';

export const KEY = 'scroll-handler'

export const ScrollHandler: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, module)
  store.subscribe(scrollDataPersistPlugin)
}
