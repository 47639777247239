import * as types from './mutation-types'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

const mutationToWatch = [types.UPDATE_SCROLL_RECORDS].map(m => `scroll-handler/${m}`)

const scrollDataPersistPlugin = (mutation, state) => {
  const scrollDataStorage = StorageManager.get('scrolldata')

  if (mutationToWatch.includes(mutation.type)) {
    scrollDataStorage.setItem('scrollRecords', mutation.payload)
  }
}

export default scrollDataPersistPlugin
