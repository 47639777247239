import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'

const microsoftClarity = {
  namespaced: true
}

export const MicrosoftClarityModule: StorefrontModule = function ({ store, appConfig }) {
  store.registerModule('microsoftClarity', microsoftClarity)

  if (!isServer) {
    let a = document.getElementsByTagName('head')[0];
    let r = document.createElement('script');
    r.async = true;
    r.text = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "ofuz22s8m4");`;
    a.appendChild(r);
  }
}
