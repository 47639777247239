import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import RootState from '@vue-storefront/core/types/RootState'
import config from 'config'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl'
import { Module } from 'vuex'

export const module: Module<any, RootState> = {
  namespaced: true,
  state: {
    popularArtists: []
  },
  mutations: {
    'SET_POPULAR_ARTISTS' (state, popularArtists) {
      state.popularArtists = popularArtists
    }
  },
  getters: {
    getPopularArtists: (state) => state.popularArtists
  },
  actions: {
    async loadPopularArtists ({ commit, dispatch }, { limit }) {
      const entityType = 'artist'
      const result = await dispatch('loadPopularEntities', { entityType, limit })

      if (result) {
        commit('SET_POPULAR_ARTISTS', result)
      }
    },
    async loadPopularEntities (_, { entityType, limit }) {
      const params = `entityType=${entityType}&limit=${limit}`
      const { result } = await TaskQueue.execute({
        url: processLocalizedURLAddress(
          getApiEndpointUrl(config.popular, 'endpoint') + `?${params}`
        ),
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })

      return result
    }
  }
}
