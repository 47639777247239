import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { isServer } from '@vue-storefront/core/helpers'
import { STORAGE } from '..'
import Vue from 'vue'

const MARKETING_GROUP_ID = 2
const ANALYTICAL_GROUP_ID = 3

const MARKETING_CONSENT = 'marketing'
const ANALYTICAL_CONSENT = 'analytical'

const MARKETING_CONSENT_DATA = {
  'ad_storage': 'granted',
  'ad_user_data': 'granted',
  'ad_personalization': 'granted'
}
const ANALYTICAL_CONSENT_DATA = {
  'analytics_storage': 'granted'
}

const DEFAULT_CONSENT = {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied',
  'functionality_storage': 'granted',
  'personalization_storage': 'denied',
  'security_storage': 'granted'
}

/**
 * Requires to Lazy-Load global vuex store
 * as claims store is registered during initTheme in the old way
 * that's why it is not accessible here during build
 */
const GLOBAL_STORE: {
  initialized: boolean,
  store: any,
  initializationPromise: Promise<void> | null
} = {
  initialized: false,
  store: {},
  initializationPromise: null
}

const initializeStore = async () => {
  try {
    const { default: store } = await import('@vue-storefront/core/store');
    GLOBAL_STORE.store = store;
    GLOBAL_STORE.initialized = true;
  } catch (error) {
    console.error('Failed to initialize store:', error);
    throw new Error('Store initialization failed');
  }
};

const ensureStoreInitialized = async () => {
  if (!GLOBAL_STORE.initialized) {
    await initializeStore()
  }
};

const setDefaultConsent = () => {
  if (isServer) {
    return
  }

  /**
   * Here we do not use global gtag as it should not
   * be yet initialized to make sure default are set before
   */
  (window as any).dataLayer = (window as any).dataLayer || [];

  const gtag = function (_command: string, _action: string, _params?: Record<string, string>): void {
    (window as any).dataLayer.push(arguments);
  }

  gtag(
    'consent',
    'default',
    DEFAULT_CONSENT
  );
}

const enableConsent = (props) => {
  if ((Vue as any).prototype.$gtag) {
    (Vue as any).prototype.$gtag.query('consent', 'update', props)
  }
}

const enableConsentType = (type) => {
  if (isServer) {
    return
  }

  if (type === MARKETING_CONSENT) {
    enableConsent(MARKETING_CONSENT_DATA)
  }

  if (type === ANALYTICAL_CONSENT) {
    enableConsent(ANALYTICAL_CONSENT_DATA)
  }
}

const cookiesAccepted = async () => {
  if (isServer) {
    return []
  }

  await ensureStoreInitialized()

  try {
    const isAccepted = await GLOBAL_STORE.store.dispatch('claims/check', { claimCode: 'cookiesAccepted' });
    const consentData = await StorageManager.get(STORAGE).getItem('gdprCookieSetup');

    if (isAccepted.value) {
      return consentData.cookeGroupIds.map(id => +id)
    }
  } catch (error) {
    console.error('Error during cookies acceptance check:', error);
  }

  return []
}

const isAccepted = async (type) => {
  const acceptedCookies = await cookiesAccepted()

  if (type === MARKETING_CONSENT) {
    return acceptedCookies.includes(MARKETING_GROUP_ID)
  }

  if (type === ANALYTICAL_CONSENT) {
    return acceptedCookies.includes(ANALYTICAL_GROUP_ID)
  }

  return false
}

const updateConsent = async () => {
  const isAcceptedMarketing = await isAccepted(MARKETING_CONSENT)
  const isAcceptedAnalytics = await isAccepted(ANALYTICAL_CONSENT)

  if (isAcceptedMarketing) {
    enableConsentType(MARKETING_CONSENT)
  }
  if (isAcceptedAnalytics) {
    enableConsentType(ANALYTICAL_CONSENT)
  }
}

export {
  setDefaultConsent,
  updateConsent,
  isAccepted,
  cookiesAccepted
}
