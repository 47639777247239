import { module } from './store'
import { extendStore, isServer } from '@vue-storefront/core/helpers'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { AsyncDataLoader } from '@vue-storefront/core/lib/async-data-loader'
import { removeStoreCodeFromRoute } from '@vue-storefront/core/lib/multistore'

export const STORAGE_KEY = 'url-rewrite'

export const UrlRewrites: StorefrontModule = function ({ router, store }) {
  StorageManager.init(STORAGE_KEY)

  extendStore('url', module)

  router.beforeEach(async (from, to, next) => {
    if (isServer) {
      AsyncDataLoader.push({
        execute: async ({ route, context }) => {
          if (context && route) {
            const [path, queryParams] = (removeStoreCodeFromRoute(route.fullPath) as string).split('?');

            const fromUrl = path.replace(/\.*\/?$/, '').replace(/^\//, '')
            const redirect = await store.dispatch('url/getRewrite', fromUrl)
            const redirectType = redirect.redirect_type ? Number(redirect.redirect_type) : 302

            if (redirect) {
              if (redirect.taget_path === 'home' || redirect.target_path === '/') {
                context.server.response.redirect(301, '/')
                context.server.response.end()
                return context;
              }
              redirect.target_path = queryParams ? redirect.target_path.concat('?', queryParams) : redirect.target_path
              context.server.response.redirect(redirectType, `/${redirect.target_path}`)
              context.server.response.end()
              return context;
            }
          }
        }
      })
    }

    next()
  })

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('url_rewrite', {
      queryProcessor: query => query,
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'url_rewrite', start, size)
      }
    })
  })

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('disabled_url_rewrite', {
      queryProcessor: query => query,
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'disabled_url_rewrite', start, size)
      }
    })
  })
}
