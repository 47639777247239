import {StorefrontModule} from '@vue-storefront/core/lib/modules';
import {extendStore} from '@vue-storefront/core/helpers';

const checkoutModule = {
  getters: {
    isVirtualCart: ({ cartItems }) => cartItems.length ? cartItems.every(itm => itm.type_id === 'downloadable' || itm.type_id === 'virtual' || itm.type_id === 'configurable') : false
  },
}

export const Evenemangsbiljetter: StorefrontModule = function () {
  extendStore('cart', checkoutModule);
}
