import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { gdprCookiePopupModule } from './store'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

export const KEY = 'gdpr-cookie-popup'
export const STORAGE = 'gdpr'

export const GdprCookiePopup: StorefrontModule = function ({ app, store }) {
  StorageManager.init(STORAGE)
  store.registerModule(KEY, gdprCookiePopupModule)
}
