import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ScrollState from '../types/ScrollState'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import * as types from './mutation-types'

const actions: ActionTree<ScrollState, RootState> = {
  async saveScrollRecord (context, { url, position }) {
    let records = await StorageManager.get('scrolldata').getItem('scrollRecords')
    const newRecord = {
      url: url,
      position: position,
      timestamp: Date.now()
    }
    if (Array.isArray(records) && records.length > 0) {
      const i = records.findIndex(e => e.url === url)
      if (i >= 0) {
        records.splice(i, 1)
      } else if (records.length >= 3) {
        records.shift()
      }
      records.push(newRecord)
    } else {
      records = [newRecord]
    }
    context.commit(types.UPDATE_SCROLL_RECORDS, records)

    return newRecord
  },
  async loadScrollHistory (context, { url }) {
    let records = await StorageManager.get('scrolldata').getItem('scrollRecords')
    let position = 0
    if (Array.isArray(records) && records.length > 0) {
      const data = records.filter(e => e.url === url)
      position = data.length > 0 ? data[0].position : 0
    }
    context.commit(types.SET_CURRENT_SCROLL_POSITION, position)

    return position
  },
  async resetScrollPosition (context) {
    context.commit(types.SET_CURRENT_SCROLL_POSITION, 0)
  },
  async loadScrollData (context) {
    const records = await StorageManager.get('scrolldata').getItem('scrollRecords')
    context.commit(types.SET_SCROLL_RECORDS, records)
  }
}

export default actions
