import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import RootState from '@vue-storefront/core/types/RootState'
import StripeState from './types/StripeState'
import { Module } from 'vuex'
import config from 'config'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

export const module: Module<StripeState, RootState> = {
  namespaced: true,
  actions: {
    async getRequiresAction (context, { orderId }) {
      const endpoint = config.stripe.requiresActionEndpoint
      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(endpoint),
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            orderId: orderId
          })
        }
      })
      return response?.code === 200 && response.result ? response.result : null;
    },
    async fetchMandateData () {
      const endpoint = config.stripe.mandateDataEndpoint

      const response = await TaskQueue.execute({
        url: processLocalizedURLAddress(endpoint),
        payload: {
          method: 'GET'
        }
      })
      return response?.code === 200 && response.result ? response.result : null;
    },
    clearStripeStorage () {
      const stripeStorage = StorageManager.get('stripe')
      stripeStorage.removeItem('orderTotals')
      stripeStorage.removeItem('confirmation')
      stripeStorage.removeItem('order')
      stripeStorage.removeItem('confirmationTime')
    }
  }
}
