import VueRouter from 'vue-router'
import rootStore from '@vue-storefront/core/store'

const encodeReserveRE = /[!'()*]/g
const encodeReserveReplacer = c => '%' + c.charCodeAt(0).toString(16)
const commaRE = /%2C/g
const leftBracketRE = /%5B/g
const rightBracketRE = /%5D/g

const encode = str => encodeURIComponent(str)
  .replace(encodeReserveRE, encodeReserveReplacer)
  .replace(commaRE, ',')
  .replace(leftBracketRE, '[')
  .replace(rightBracketRE, ']')

const decode = decodeURIComponent

const customParseQuery = (query: string): Dictionary<string> => {
  const res = {}
  query = query.trim().replace(/^(\?|#|&)/, '')
  if (!query) {
    return res
  }

  query.split('&').forEach(param => {
    const parts = param.replace(/\+/g, ' ').split('=')
    const key = decode(parts.shift())
    let val = parts.length > 0 ? decode(parts) : null

    // Remove the square brackets and split by comma
    val = val.substring(1, val.length - 1).split(',').map(v => v.trim())

    if (res[key] === undefined) {
      res[key] = val
    } else if (Array.isArray(res[key])) {
      res[key].push(...val)
    } else {
      res[key] = [res[key], val]
    }
  })

  return res
}

const customStringifyQuery = (obj: Dictionary<string>): string => {
  const res = obj
    ? Object.keys(obj)
      .map(key => {
        const val = obj[key]

        if (val === undefined) {
          return ''
        }

        if (val === null) {
          return encode(key)
        }

        if (Array.isArray(val)) {
          return `${encode(key)}=[${val.map(encode).join(',')}]`
        }

        return `${encode(key)}=${encode(val)}`
      })
      .filter(x => x.length > 0)
      .join('&')
    : null

  return res ? `?${res}` : ''
}

export const createCustomRouter = (): VueRouter => {
  return new VueRouter({
    mode: 'history',
    base: __dirname,
    scrollBehavior: (to, from) => {
      if (to.hash) {
        return {
          selector: to.hash
        }
      }
      if (rootStore.getters['url/isBackRoute']) {
        const { scrollPosition = { x: 0, y: 0 } } = rootStore.getters['url/getCurrentRoute']
        return scrollPosition
      } else if (to.path !== from.path) {
        return { x: 0, y: 0 }
      }
    },
    parseQuery: customParseQuery,
    stringifyQuery: customStringifyQuery
  })
}
