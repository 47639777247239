import { Logger } from '@vue-storefront/core/lib/logger'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import rootStore from '@vue-storefront/core/store'
import { isServer } from '@vue-storefront/core/helpers'
import { cartHooksExecutors } from '@vue-storefront/core/modules/cart/hooks'
import { CartService } from '@vue-storefront/core/data-resolver';
import { ProductService } from '@vue-storefront/core/data-resolver/ProductService'

import get from 'lodash-es/get'
import { createOrderData, createShippingInfoData } from '@vue-storefront/core/modules/cart/helpers';

export default {
  actions: {
    async restoreLastCart ({ commit, dispatch }, { clientToken = '', forceClientState = false }: {
      clientToken?: string,
      forceClientState?: boolean
    } = {}) {
      if (isServer) return Logger.warn('Cart cannot be restored from server', 'cart')()
      commit(types.CART_LOAD_CART_SERVER_TOKEN, clientToken)
      Logger.debug('Restoring last cart', 'cart')()
      await dispatch('setDefaultCheckoutMethods')
      await dispatch('sync', { forceClientState: false })
      const { result, resultCode } = await CartService.getItems()
      let mergedItems = [];
      for (const item of result) {
        const product = await ProductService.getProductByKey({
          options: {
            sku: item.sku
          },
          key: 'sku',
          skipCache: false
        })
        const parentProduct = await rootStore.dispatch('product/findConfigurableParent', { product })
        if (product) {
          mergedItems.push({
            ...parentProduct,
            ...product,
            ...item
          });
        }
      }
      mergedItems.forEach(item => {
        item.options.forEach(option => {
          item.configurable_options.forEach(configOption => {
            if (option.label === configOption.label) {
              if (configOption.attribute_code && item[configOption.attribute_code]) {
                option.value = item[configOption.attribute_code]
              }
              configOption.values.forEach(value => {
                if (value.value_index == option.value) { // eslint-disable-line
                  option.value = value.label
                }
              })
            }
          })
        })
      })

      commit(types.CART_LOAD_CART, mergedItems)
      await dispatch('synchronizeCart', { forceClientState })

      cartHooksExecutors.afterLoad(result)
    }
  }
}
