import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export const getProduct = (item) => {
  let product = {}
  if (item) {
    const attributeMap: string[]|Record<string, any>[] = config.googleTagManager.product_attributes
    attributeMap.forEach(attribute => {
      const isObject = typeof attribute === 'object'
      let attributeField = isObject ? Object.keys(attribute)[0] : attribute
      let attributeName = isObject ? Object.values(attribute)[0] : attribute

      if (item.hasOwnProperty(attributeField) || product.hasOwnProperty(attributeName)) {
        const value = item[attributeField] || product[attributeName]
        if (value) {
          product[attributeName] = value
        }
      }
    })

    const { category } = item
    if (category && category.length > 0) {
      product['category'] = category.slice(-1)[0].name
    }

    if (product && !product.hasOwnProperty('currency')) {
      product['currency'] = config.googleTagManager.default_currency
    }
  }

  return product
}
