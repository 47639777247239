import { GetterTree } from 'vuex'
import RootState from 'core/types/RootState'
import ScrollState from '../types/ScrollState'

const getters: GetterTree<ScrollState, RootState> = {
  getScrollRecords: state => state.scrollRecords,
  getScrollPosition: state => state.scrollPosition
}

export default getters
