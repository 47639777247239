import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'

const wiserNotify = {
  namespaced: true
}

export const WiserNotifyModule: StorefrontModule = function ({ store, appConfig }) {
  store.registerModule('wiserNotify', wiserNotify)

  if (!isServer) {
    let a = document.getElementsByTagName('head')[0];
    let r = document.createElement('script');
    r.text = '!function () { if (window.t4hto4) console.log("WiserNotify pixel installed multiple time in this page"); else { window.t4hto4 = !0; var t = document, e = window, n = function () { var e = t.createElement("script"); e.type = "text/javascript", e.async = !0, e.src = "https://pt.wisernotify.com/pixel.js?ti=7ni7r1jl8mx2ams", document.body.appendChild(e) }; "complete" === t.readyState ? n() : window.attachEvent ? e.attachEvent("onload", n) : e.addEventListener("load", n, !1) } }();'
    a.appendChild(r);
  }
}
