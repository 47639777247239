import ScrollState from '../types/ScrollState'
import RootState from '@vue-storefront/core/types/RootState'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { Module } from 'vuex'

export const module: Module<ScrollState, RootState> = {
  namespaced: true,
  state: {
    scrollRecords: [],
    scrollPosition: 0
  },
  actions,
  getters,
  mutations
}
