export const uiStore = {
  namespaced: true,
  state: {
    sidebar: false,
    microcart: false,
    searchpanel: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    authElem: 'login',
    checkoutMode: false,
    openMyAccount: false,
    showQuantityPopup: false,
    showTicketsLeftPopup: null,
    showBestSellerPopup: null,
    openTicketLoader: false,
    ticketLoaderMessage: 'Reserving your tickets...',
    openProductRouteLoader: false,
    showTimeIsUpPopup: false,
    productDetails: {
      open: false,
      item: 0
    },
    submenu: {
      depth: false,
      path: []
    },
    searchSuggestions: false,
    isAuthenticated: false
  },
  mutations: {
    setCheckoutMode (state, action) {
      state.checkoutMode = action === true
    },
    setMicrocart (state, action) {
      state.microcart = action === true
      state.overlay = action === true
    },
    setSidebar (state, action) {
      state.sidebar = action === true
      state.overlay = action === true
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id)
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop()
        }, 300)
      }
      state.submenu.depth = state.submenu.depth > 0 && depth
    },
    setSearchpanel (state, action) {
      state.searchpanel = action === true
      state.overlay = action === true
    },
    setOverlay (state, action) {
      state.overlay = action === true
    },
    setLoader (state, action) {
      state.loader = action === true
    },
    setAuthElem (state, action) {
      state.authElem = action
    },
    setShowQuantityPopup (state, action) {
      state.showQuantityPopup = action === true
    },
    setShowTicketsLeftPopup (state, sku) {
      state.showTicketsLeftPopup = sku
    },
    setShowBestSellerPopup (state, sku) {
      state.showBestSellerPopup = sku
    },
    setProductDetails (state, { open, item }) {
      if (item) {
        state.productDetails.item = item
      } else {
        state.productDetails.item = 0
      }
      state.productDetails.open = open
    },
    setSearchSuggestions (state, action) {
      state.searchSuggestions = action === true
    },
    setTicketLoader (state, payload) {
      const { action, message } = payload
      state.openTicketLoader = action === true
      state.ticketLoaderMessage = message ? message : '';
    },
    setProductRouteLoader (state, action) {
      state.openProductRouteLoader = action === true
    },
    setShowTimeIsUpPopup (state, action) {
      state.showTimeIsUpPopup = action === true
    },
    setIsAuthenticated (state, action) {
      state.isAuthenticated = action === true
    }
  },
  actions: {
    toggleMicrocart ({ commit, state }) {
      commit('setMicrocart', !state.microcart)
    },
    closeMicrocart ({ commit, state }) {
      if (state.microcart) commit('setMicrocart', false)
    }
  }
}
