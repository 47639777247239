import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isEnabled, getProduct } from '../helpers';

export function afterRegistration (config, store: Store<any>) {
  if (isEnabled(config.googleTagManager.id)) {
    const GTM: VueGtm = (Vue as any).gtm

    const storeView = currentStoreView()
    const currencyCode = config.googleTagManager.default_currency

    store.subscribe(({ type, payload }, state) => {
      if (payload && payload.product) {
        // Adding a Product to a Shopping Cart
        if (type === 'cart/cart/ADD') {
          GTM.trackEvent({
            event: 'add_to_cart',
            ecommerce: {
              items: [
                getProduct(payload.product)
              ]
            }
          });
        }

        // Removing a Product from a Shopping Cart
        if (type === 'cart/cart/DEL') {
          GTM.trackEvent({
            event: 'remove_from_cart',
            ecommerce: {
              items: [
                getProduct(payload.product)
              ]
            }
          });
        }

        // Measuring Views of Product Details
        if (type === 'product/product/SET_CURRENT') {
          GTM.trackEvent({
            event: 'view_item',
            ecommerce: {
              items: [
                getProduct(payload.product)
              ]
            }
          });
        }

        // Measuring Views of Product Details
        if (type === 'product/product/SET_PRODUCT_CURRENT') {
          GTM.trackEvent({
            ecommerce: {
              items: [
                getProduct(payload.product)
              ]
            }
          });
        }
      } else {
        // Measuring Views of Product Details
        if (type === 'product/product/SET_CURRENT' && state.product && state.product.current) {
          GTM.trackEvent({
            event: 'view_item',
            ecommerce: {
              items: [
                getProduct(state.product.current)
              ]
            }
          });
        }
      }

      // Setting customer data
      if (type === 'order/orders/ADD_SESSION_STAMPS') {
        if (payload.addressInformation.billingAddress) {
          const userData = payload.addressInformation.billingAddress
          GTM.trackEvent({
            customerData: {
              firstname: userData.firstname || '',
              lastname: userData.lastname || '',
              country: userData.country_id || '',
              email: userData.email || '',
              telephone: userData.telephone || '',
              street: userData.street[0] || '',
              city: userData.city || '',
              postcode: userData.postcode || ''
            }
          })
        }
      }

      // Measuring Purchases
      if (type === 'order/orders/LAST_ORDER_CONFIRMATION') {
        const orderId = payload.confirmation.backendOrderId
        const products = payload.order.products.map(product => getProduct(product))
        store.dispatch(
          'user/getOrdersHistory',
          { refresh: true, useCache: false }
        ).then(() => {
          const orderHistory = state.user.orders_history
          const order = state.user.orders_history ? orderHistory.items.find((order) => order['entity_id'].toString() === orderId) : null
          GTM.trackEvent({
            event: 'purchase',
            ecommerce: {
              transaction_id: orderId,
              affiliation: order ? order.store_name : '',
              value: order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.base_grand_total ? state.cart.platformTotals.base_grand_total : '',
              tax: order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.base_tax_amount ? state.cart.platformTotals.base_tax_amount : '',
              shipping: order ? order.total_due : state.cart.platformTotals && state.cart.platformTotals.base_shipping_amount ? state.cart.platformTotals.base_shipping_amount : '',
              currency: currencyCode,
              coupon: '',
              items: products
            }
          })
        })
      }
    })
  }
}
