import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import TrustpilotState from './types/TrustpilotState'

const mutations: MutationTree<TrustpilotState> = {
  [types.SERVICE_INVITATION_URL] (state, { invitationUrl }) {
    state.invitationUrl = invitationUrl
  }
}

export default mutations
