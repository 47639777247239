import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers';
import config from 'config';

const logRocket = {
  namespaced: true
}

export const LogRocketModule: StorefrontModule = function ({ store, appConfig }) {
  store.registerModule('logRocket', logRocket)

  if (!isServer) {
    if (config?.logrocket?.enable) {
      let a = document.getElementsByTagName('head')[0];
      let r = document.createElement('script');
      r.text = 'window.LogRocket && window.LogRocket.init(\'h9nv76/evenemangsbiljetterse\');'
      a.appendChild(r);
    }
  }
}
