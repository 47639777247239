import { Module } from 'vuex'
import RootState from 'core/types/RootState'
import { CookiePopupState } from '../types/CookiePopupState'
import i18n from '@vue-storefront/i18n'

export const gdprCookiePopupModule: Module<CookiePopupState, RootState> = {
  namespaced: true,
  state: {
    cookiePopupState: [
      {
        checked: true,
        description: i18n.t('These cookies are necessary for the website to function. Without these cookies, the website cannot function properly.'),
        groupId: 1,
        isEssential: true,
        name: i18n.t('Mandatory cookies')
      },
      {
        checked: true,
        description: i18n.t('These are cookies for collecting information and reporting website usage statistics. Cookies store data about users and information about their behavior, which allows advertising services to target more groups of audiences. It is also possible to provide a more personalized user experience based on the information collected.'),
        groupId: 2,
        isEssential: false,
        name: i18n.t('Marketing cookies')
      },
      {
        checked: true,
        description: i18n.t('These are cookies for collecting information and reporting website usage statistics without personally identifying individual visitors to') + '<a href="https://business.safety.google/privacy/" target="_blank"> Google</a>' + '.',
        groupId: 3,
        isEssential: false,
        name: i18n.t('Analytical cookies')
      }
    ]
  },
  getters: {
    getCookieData: (state) => state.cookiePopupState
  }
}
