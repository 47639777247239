import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import RootState from '@vue-storefront/core/types/RootState'
import config from 'config'
import { Module } from 'vuex'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';

export const module: Module<any, RootState> = {
  namespaced: true,
  state: {
    venues: []
  },
  mutations: {
    'SET_VENUES' (state, venues) {
      state.venues = venues
    }
  },
  getters: {
    getList: (state) => state.venues
  },
  actions: {
    async loadVenues ({ commit }) {
      const { result } = await TaskQueue.execute({
        url: processLocalizedURLAddress(
          getApiEndpointUrl(config.venues, 'endpoint')
        ),
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        }
      })

      commit('SET_VENUES', result.items)
    }
  }
}
