import { Module } from 'vuex'
import GoogleTagManagerState from '../types/GoogleTagManagerState'
import { isEnabled, getProduct } from '../helpers'
import config from 'config'
import Vue from 'vue'
import VueGtm from 'vue-gtm'

export const googleTagManagerModule: Module<GoogleTagManagerState, any> = {
  namespaced: true,
  state: {
    key: null
  },
  actions: {
    async checkoutEvent ({ dispatch, commit }, products) {
      if (isEnabled(config.googleTagManager.id)) {
        let items = []
        products.forEach(product => items.push(getProduct(product)))
        const GTM: VueGtm = (Vue as any).gtm;
        GTM.trackEvent({
          event: 'checkout',
          ecommerce: {
            items
          }
        });
      }
    },
    async trackEvent ({ dispatch, commit }, event) {
      if (isEnabled(config.googleTagManager.id)) {
        const GTM: VueGtm = (Vue as any).gtm;
        GTM.trackEvent(event);
      }
    }
  }
}
